import React, { useState } from 'react';

const PasswordResetForm = ({ handlePasswordReset }) => {
  const [state, setState] = useState({
    username: '',
  });

  const handleChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setState({ ...state, [name]: value });
  };

  const [status, setStatus] = useState(true);

  return (
    <>
      {/* <form
        onSubmit={(e) => {
          if (status) {
            handlePasswordReset(e, state);
            setStatus(false);
          } else e.preventDefault();
        }}
      >
        <h2 className="font-weight-bold text-primary mb-3">
          OSA Reset Password Form
        </h2>
        <div className="form-group row">
          <p className="col-sm-1 col-form-label">Email</p>
          <div className="col-sm-11 pl-3 pl-md-0 ">
            <input
              type="email"
              required
              name="username"
              placeholder="Email"
              autoComplete="new-email"
              className="form-control"
              value={state.username}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="d-flex">
          <input
            className={`btn btn-primary ml-auto ${status ? '' : 'disabled'}`}
            value="Reset Password"
            type="submit"
          />
        </div>
      </form> */}

      <form
        class="form"
        onSubmit={(e) => {
          if (status) {
            handlePasswordReset(e, state);
            setStatus(false);
          } else e.preventDefault();
        }}
      >
        <p id="heading">Reset Password</p>
        <div class="field">
          <svg
            viewBox="0 0 16 16"
            height="16"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
            class="input-icon"
          >
            <path
              fill="white"
              d="M13.106 7.222c0-2.967-2.249-5.032-5.482-5.032-3.35 0-5.646 2.318-5.646 5.702 0 3.493 2.235 5.708 5.762 5.708.862 0 1.689-.123 2.304-.335v-.862c-.43.199-1.354.328-2.29.328-2.926 0-4.813-1.88-4.813-4.798 0-2.844 1.921-4.881 4.594-4.881 2.735 0 4.608 1.688 4.608 4.156 0 1.682-.554 2.769-1.416 2.769-.492 0-.772-.28-.772-.76V5.206H8.923v.834h-.11c-.266-.595-.881-.964-1.6-.964-1.4 0-2.378 1.162-2.378 2.823 0 1.737.957 2.906 2.379 2.906.8 0 1.415-.39 1.709-1.087h.11c.081.67.703 1.148 1.503 1.148 1.572 0 2.57-1.415 2.57-3.643zm-7.177.704c0-1.197.54-1.907 1.456-1.907.93 0 1.524.738 1.524 1.907S8.308 9.84 7.371 9.84c-.895 0-1.442-.725-1.442-1.914z"
            ></path>
          </svg>
          <input
            type="email"
            required
            name="username"
            placeholder="Email"
            autoComplete="new-email"
            className="input-field"
            value={state.username}
            onChange={handleChange}
          />
        </div>
        <div class="btn">
          <button class="button1">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Reset
            Password&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </button>
        </div>
      </form>
    </>
  );
};

export default PasswordResetForm;
