const DevData = {
  'Our Team': [
    {
      img: 'sir.jpeg',
      name: 'Dr. Rajiv Ratn Shah',
      bio: 'Head Of Department at IIITD Department of Human-Centered Design',
      linkedin: 'https://www.linkedin.com/in/rajivratn/',
      github: 'https://github.com/midas-research',
    },
    {
      img: 'karan.jpg',
      name: 'Karan Baboota',
      bio: 'Trying to make the world a more automated place one line of code at a time',
      linkedin: 'https://www.linkedin.com/in/karan-baboota/',
      github: 'https://www.github.com/karan20210',
    },
    {
      img: 'bhavya.jpeg',
      name: 'Bhavya Jain',
      bio: 'Still REACTing to these portals XD',
      linkedin: 'https://www.linkedin.com/in/bhavya-0811-jain/',
      github: 'https://github.com/bhavya20428',
    },
    {
      img: 'subhanshu.jpeg',
      name: 'Subhanshu Bansal',
      bio: 'Inventing New Everyday 😉',
      linkedin: 'https://www.linkedin.com/in/subhanshu-bansal-7741b2200/',
      github: 'https://github.com/Subhanshu-2411',
    },
    {
      img: 'shivoy.jpeg',
      name: 'Shivoy Arora',
      bio: '',
      linkedin: 'https://www.linkedin.com/in/arorashivoy',
      github: 'https://github.com/arorashivoy',
    },
    {
      img: 'anuj.jpeg',
      name: 'Anuj Yadav',
      bio: "I am a tech innovator with excellent skills in web development, machine learning, and data analytics, who is constantly pushing the boundaries of what's possible in the tech world.",
      linkedin: 'https://www.linkedin.com/in/anujxyadav',
      github: 'https://github.com/AnujxYadav',
    },
    {
      img: 'sk.jpg',
      name: 'Sumit Kumar',
      bio: "I don't always test my code, but when I do, I do it in production.",
      linkedin: 'https://www.linkedin.com/in/sumit-kumar-06b022213/',
      github: 'https://github.com/sk1802',
    },
    {
      img: 'rishi.jpeg',
      name: 'Rishi Raj Jain',
      bio: 'Synchronising my knowledge with community about Web Development, Caching, Edge Computing, Serverless, front-end ecosystems.',
      linkedin: 'https://linkedin.com/in/rishi-raj-jain',
      github: 'http://github.com/rishi-raj-jain',
    },
    {
      img: 'arnav.jpeg',
      name: 'Arnav Gupta',
      bio: 'A tech-savvy problem ninja, constantly hacking away at challenges with a versatile skill set and harnessing the power of emerging technologies to optimize processes and create cutting-edge solutions.',
      linkedin: 'https://www.linkedin.com/in/the-arnav-gupta/',
      github: 'https://github.com/ArnavGupta21',
    },
    {
      img: 'vaibhav.jpeg',
      name: 'Vaibhav Saxena',
      bio: 'The coder that designs. The designer that codes',
      github: 'https://github.com/VaibhavSaxena-2019342',
      linkedin: 'https://www.linkedin.com/in/vaibhav-saxena-ba8ba8208/',
    },
    {
      img: 'soumyadeep.jpeg',
      name: 'Soumyadeep Paul',
      bio: 'The software developer who loves Java more than JavaScript.',
      github: 'https://github.com/soumyadeepsp',
      linkedin: 'https://www.linkedin.com/in/soumyadeeppaul123/',
    },
  ],
  // 'Research Apps': [],
};

export default DevData;
