import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

import { GoogleOAuthProvider } from '@react-oauth/google';

import './custom.scss';

// ReactDOM.render(<App />, document.getElementById('root'));
ReactDOM.render(
  <GoogleOAuthProvider clientId="189115914440-m0spg7kq63il3k79r59ajfj04eqfhksg.apps.googleusercontent.com">
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </GoogleOAuthProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
