const AppsData = {
  'College Life': [
    {
      img: 'fms2.svg',
      title: 'FMS App',
      tags: ['fms', 'app', 'complaint', 'internal', 'intranet'],
      desc: 'FMS App for IIITD is the complaints portal designed to track and register tickets.',
      website: 'http://fms.fh.iiitd.edu.in/',
    },
    {
      img: 'hostel.svg',
      title: 'Hostel App',
      tags: ['hostel', 'app', 'request', 'internal', 'intranet'],
      desc: 'Hostel Allocation Portal for students to book a hostel room for their stay at IIIT Delhi',
      website: 'http://hostel.fh.iiitd.edu.in/',
    },
    {
      img: 'shareiiit.svg',
      title: 'ShareIIITD',
      tags: ['share', 'app', 'resource', 'books', 'skills'],
      desc: 'A platform where IIITDians can share all resources and reach out to fellow college students and alumni',
      website: 'http://share.fh.iiitd.edu.in/',
    },
    {
      img: 'bookiiit.svg',
      title: 'BOOKiiIT App',
      tags: ['space booking', 'app', 'event', 'internal', 'intranet'],
      desc: 'BOOKiiIT helps in booking and management of bookings of spaces in IIITD. ',
      website: 'http://booking.fh.iiitd.edu.in/',
    },
    {
      img: 'achieve.svg',
      title: 'AchieveIIITD',
      tags: ['achievement', 'project', 'app', 'internal', 'intranet'],
      desc: 'AchieveIIITD is achievement portal of IIITD for students as well as staff members.',
      website: 'http://achieve.fh.iiitd.edu.in/',
    },
    {
      img: 'wbc.svg',
      title: 'Well Being Cell Portal',
      tags: ['wellbeing', 'app', 'booking', 'counseling', 'intranet'],
      desc: 'Well Being Cell Portal developed to facilitate booking of appointments with psychologists at IIIT Delhi',
      website: 'http://wellbeing.fh.iiitd.edu.in/',
    },
    {
      img: 'nodues.svg',
      title: 'No Dues Portal',
      tags: ['nodues', 'app', 'dues', 'intranet'],
      desc: 'No Dues portal which helps to manage the dues of a student at the time of graduation',
      website: 'http://nodues.fh.iiitd.edu.in/',
    },
    {
      img: 'crams-logo.svg',
      title: 'CRAMS',
      tags: ['course', 'recommendation', 'ML', 'app'],
      desc: 'Course Recommendation and Management System helps you choose courses which forms an integral part of your university experience.',
      website: 'http://crams.fh.iiitd.edu.in/',
    },
  ],
  // 'Research Apps': [],
};

export default AppsData;
