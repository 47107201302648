/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/button-has-type */
import React from 'react';
import { FaLinkedinIn, FaGithub } from 'react-icons/fa';

const DevCard = ({ card }) => {
  return (
    <div className="card">
      <h2 className="dev-name">{card.name}</h2>
      <img
        // eslint-disable-next-line
        src={require(`../static/apps/${card.img}`)}
        className="dev-img card-img-top"
        style={{ height: '175px' }}
        alt=""
      />
      <div className="content">
        <p className="title">{card.bio}</p>
        <div className="button_wrap">
          <a href={card.linkedin}>
            <span>
              <FaLinkedinIn size={30} />
            </span>
          </a>
          <a href={card.github}>
            <span>
              <FaGithub size={30} />
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default DevCard;
