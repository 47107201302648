import React from 'react';

const MessageBox = ({ message }) => {
  return (
    <>
      <h2 className="font-weight-bold text-primary mb-3">Email Sent!</h2>
      <p>{message}</p>
    </>
  );
};

export default MessageBox;
