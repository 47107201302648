/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-use-before-define */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { mdiChevronDown, mdiChevronRight, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useEffect, useState } from 'react';
import {
  Container,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Collapse,
  Modal,
  Alert,
  ModalBody,
} from 'reactstrap';
import styled from 'styled-components';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import GoogleButton from 'react-google-button';
import AppsData from './components/AppsData';
import AppsWrapper from './components/AppsWrapper';
import AuthControls from './components/AuthControls';
import LoginForm from './components/LoginForm';
import SignUpForm from './components/SignUpForm';
import PasswordResetForm from './components/PasswordResetForm';
import ChangePasswordForm from './components/ChangePasswordForm';

import Config from './Config';
import EditProfileForm from './components/EditProfileForm';
import MessageBox from './components/MessageBox';
import DevWrapper from './components/DevWrapper';

const App = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [message, setMessage] = useState('');
  const [usernameForPassReset, setUsernameForPassReset] = useState('');
  const [tokenForPassReset, setTokenForPassReset] = useState('');

  const [formErr, setFormErr] = useState('');
  const toggleModal = () => setModal(!modal);
  const toggleNavbar = () => setCollapsed(!collapsed);

  const [user, setUser] = useState();
  const [profile, setProfile] = useState();

  const [developers, setDevelopers] = useState(false);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
    },
    onError: (error) => console.log('Login Failed:', error),
  });

  const [state, setState] = useState({
    loggedIn: !!localStorage.getItem('token'),
    username: '',
    first_name: '',
    last_name: '',
  });

  useEffect(() => {
    let token = localStorage.getItem('token');
    if (user || token) {
      console.log(user);
      console.log(token);
      if (token == null) token = user.access_token;
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: 'application/json',
            },
          },
        )
        .then((res) => {
          setProfile(res.data);
          setState({
            loggedIn: true,
            username: res.data.email,
            first_name: res.data.given_name,
            last_name: res.data.family_name,
            is_verified: res.data.verified_email,
          });
          localStorage.setItem('token', token);
          document.cookie = `osa_token=${localStorage.getItem(
            'token',
          )};domain=fh.iiitd.edu.in`;
        })
        .catch((err) => console.log(err));
    }
    console.log(localStorage.getItem('token'));
    if (localStorage.getItem('token')) {
      fetch(`${Config.backend_base_url}core/current_user/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            document.cookie = `
            _token=${localStorage.getItem('token')};domain=fh.iiitd.edu.in`;
            return res.json();
          }
          throw Error();
        })
        .then((json) => {
          setState({ ...json, loggedIn: true });
          if (window.location.hash) {
            setModalType(window.location.hash.split('#')[1]);
            setModal(true);
          }
        })
        .catch(() => {
          localStorage.removeItem('token');
        });
    } else if (window.location.hash) {
      setModalType(window.location.hash.split('#')[1]);
      setModal(true);
    }
    if (window.location.search) {
      const params = new URLSearchParams(window.location.search);
      if (params.get('password')) {
        setTokenForPassReset(params.get('token'));
        setUsernameForPassReset(params.get('username'));
        setModalType('changepassword');
        setModal(true);
      } else {
        handleVerifyEmail(params.get('username'), params.get('token'));
      }
    }
  }, [user]);

  useEffect(() => {
    document.querySelectorAll('.scroll').forEach((item) => {
      item.addEventListener('click', (event) => {
        event.preventDefault();
        const target = item.href.split('#')[1];
        const topVal = document.getElementById(
          decodeURIComponent(target),
        ).offsetTop;
        window.scrollTo({
          top: topVal - 30,
          behavior: 'smooth',
        });
      });
    });
  }, []);

  const handleLogin = (e, data) => {
    // console.log(data);
    e.preventDefault();
    fetch(`${Config.backend_base_url}token-auth/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
        throw Error('Bad Login');
      })
      .then((json) => {
        // console.log(json);
        localStorage.setItem('token', json.token);
        document.cookie = `osa_token=${localStorage.getItem(
          'token',
        )};domain=fh.iiitd.edu.in`;
        setState({
          loggedIn: true,
          username: json.user.username,
          first_name: json.user.first_name,
          last_name: json.user.last_name,
          is_verified: json.user.is_verified,
        });
        setModal(false);
        console.log(localStorage.getItem('token'));
      })
      .catch(() => {
        setFormErr("Your email and password didn't match! Please try again.");
      });
  };

  const handleSignUp = (e, data) => {
    e.preventDefault();
    if (data.password1 === data.password2) {
      fetch(
        `${Config.backend_base_url}core/users/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...data, password: data.password1 }),
        },
        { strictErrors: true },
      )
        .then((res) => {
          if (res.status === 201) {
            return res.json();
          }
          throw Error('Bad Sign Up');
        })
        .then((json) => {
          localStorage.setItem('token', json.token);
          document.cookie = `osa_token=${localStorage.getItem(
            'token',
          )};domain=fh.iiitd.edu.in`;
          setState({
            loggedIn: true,
            username: json.username,
            first_name: json.first_name,
            last_name: json.last_name,
            is_verified: json.is_verified,
          });
          setModal(false);
        })
        .catch(() => {
          setFormErr('A user with this email already exists! Reset password?');
        });
    } else setFormErr("Your passwords don't match!");
  };

  const handlePasswordReset = (e, data) => {
    e.preventDefault();
    fetch(
      `${Config.backend_base_url}core/reset_password/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      },
      { strictErrors: true },
    ).then((res) => {
      if (res.status === 200) {
        setModalType('messagebox');
        setMessage('Password reset request has been sent to your email!');
        setUsernameForPassReset(data.username);
      }
    });
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    document.cookie = `osa_token=;domain=fh.iiitd.edu.in`;
    setState({
      loggedIn: false,
      username: '',
      first_name: '',
      last_name: '',
      is_verified: false,
    });
  };

  const handleChangePassword = (e, data) => {
    e.preventDefault();
    if (data.password1 === data.password2) {
      fetch(
        `${Config.backend_base_url}core/change_password/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...data,
            token: tokenForPassReset,
            password: data.password1,
            username: usernameForPassReset,
          }),
        },
        { strictErrors: true },
      )
        .then((res) => {
          if (res.status === 200) {
            setModalType('login');
            handleLogout();
            return setFormErr('Login to continue!');
          }
          throw Error('Bad Sign Up');
        })
        .catch(() => {
          setFormErr(
            'Some error occured! Token has expired or try again after some time.',
          );
        });
    } else setFormErr("Your passwords don't match!");
  };

  const handleVerifyEmail = (username, token) => {
    fetch(
      `${Config.backend_base_url}core/verify_email/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          username,
        }),
      },
      { strictErrors: true },
    )
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
        throw Error('Bad Sign Up');
      })
      .then((json) => {
        setState({
          loggedIn: true,
          username: json.username,
          first_name: json.first_name,
          last_name: json.last_name,
          is_verified: json.is_verified,
        });
        return setModal(false);
      })
      .catch(() => {
        setFormErr(
          'Some error occured! Token has expired or try again after some time.',
        );
      });
  };

  const handleEditProfile = (e, data) => {
    e.preventDefault();
    if (data.username === data.username1)
      fetch(
        `${Config.backend_base_url}core/edit_profile/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify(data),
        },
        { strictErrors: true },
      )
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          }
          if (res.status === 401) {
            throw Error('Please verify your email first!');
          }
          throw Error(
            'Some error occured! User already exists or try again after some time.',
          );
        })
        .then((json) => {
          if (json.is_verified)
            setState({
              loggedIn: true,
              username: json.username,
              first_name: json.first_name,
              last_name: json.last_name,
              is_verified: json.is_verified,
            });
          else {
            localStorage.removeItem('token');
            document.cookie = `osa_token=;domain=fh.iiitd.edu.in`;
            setState({
              loggedIn: false,
              username: '',
              first_name: '',
              last_name: '',
              is_verified: false,
            });
          }
          setModal(false);
        })
        .catch((err) => {
          setFormErr(err.message);
        });
    else setFormErr("Your emails don't match!");
  };

  const renderModalForm = (param) => {
    switch (param) {
      case 'messagebox':
        return <MessageBox message={message} />;
      case 'login':
        return <LoginForm handleLogin={handleLogin} />;
      case 'signup':
        return <SignUpForm handleSignUp={handleSignUp} />;
      case 'resetpassword':
        return <PasswordResetForm handlePasswordReset={handlePasswordReset} />;
      case 'changepassword':
        return (
          <ChangePasswordForm handleChangePassword={handleChangePassword} />
        );
      case 'editprofile':
        return (
          <EditProfileForm
            handleEditProfile={handleEditProfile}
            prevState={state}
          />
        );
      default:
        return <></>;
    }
  };

  const showModal = (param) => {
    window.location.hash = param;
    setModalType(param);
    setModal(true);
  };

  const verifyEmail = () => {
    fetch(
      `${Config.backend_base_url}core/resend_email/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: state.username }),
      },
      { strictErrors: true },
    ).then((res) => {
      if (res.status === 200) {
        setModalType('messagebox');
        setMessage('Email to verify account has been sent to your email!');
        setModal(true);
      }
    });
  };

  const logOut = () => {
    googleLogout();
    setProfile(null);
    localStorage.removeItem('token');
  };

  return (
    <div>
      {/* <div>
        {profile ? (
          <div>
            <img src={profile.picture} alt="user image" />
            <h3>User Logged in</h3>
            <p>Name: {profile.name}</p>
            <p>Email Address: {profile.email}</p>
            <br />
            <br />
            <button onClick={logOut}>Log out</button>
          </div>
        ) : (
          <button onClick={() => login()}>Sign in with Google 🚀 </button>
        )}
      </div> */}
      {state.loggedIn && !state.is_verified && (
        <Alert className="mx-3 mt-3 mb-0 small" color="danger">
          Please verify your email: {state.username} we sent you an email to
          activate OSA App Login System.{' '}
          <span
            aria-hidden="true"
            className="p-0 ml-auto text-danger pointer"
            onClick={verifyEmail}
          >
            <u>Resend email?</u>
          </span>
        </Alert>
      )}
      <Container fluid>
        <div className="d-flex align-items-center mb-0">
          <h1 className="mb-0 display-3 font-weight-bold ml-auto ml-lg-0 text-primary">
            FACILITY HUB
          </h1>
          <img
            // eslint-disable-next-line
            src={require(`./static/iiitd-logo.png`)}
            className="mr-auto ml-lg-0"
            height="90px"
            alt=""
          />
        </div>
        {/* <div className="d-flex">
          <p
            className="mx-auto mx-lg-0 ml-lg-1 small text-secondary text-center"
            style={{ marginTop: '-10px' }}
          >
            Facility Hub IIITD serves as the umbrella app to house all the internal apps
            used at IIITD.
          </p>
        </div> */}
      </Container>
      <Navbar color="primary" expand="md" dark>
        <div className="d-flex d-md-none w-100 text-white">
          <div
            className="d-flex align-items-center"
            onClick={toggleNavbar}
            aria-hidden="true"
          >
            Menu
            <span className="ml-1">
              <Icon
                path={collapsed ? mdiChevronRight : mdiChevronDown}
                size={0.7}
              />
            </span>
          </div>
          <AuthControls
            className="d-block d-md-none ml-auto"
            loggedIn={state.loggedIn}
            handleLogout={handleLogout}
            first_name={state.first_name}
            showModal={showModal}
            isVerified={state.is_verified}
          />
        </div>
        <Collapse isOpen={!collapsed} navbar>
          <Nav navbar>
            {Object.keys(AppsData).map((link) => (
              <NavItem className="mr-2 my-1 my-md-0 rounded-lg" key={link}>
                <CustomNavLink href={`/#${link}`} className="scroll px-md-3">
                  {link}
                </CustomNavLink>
              </NavItem>
            ))}
          </Nav>
        </Collapse>
        <AuthControls
          // className="d-none d-md-block"
          loggedIn={state.loggedIn}
          handleLogout={handleLogout}
          first_name={state.first_name}
          showModal={showModal}
          isVerified={state.is_verified}
        />
        {/* <div>
          {profile ? (
            <div>
              <p style={{ color: 'white' }}>Hi, {profile.name}</p>
              <button
                onClick={logOut}
                style={{ float: 'right', background: 'lightblue' }}
              >
                Log out
              </button>
            </div>
          ) : (
            <GoogleButton
              onClick={() => {
                login();
              }}
            />
          )}
        </div> */}
      </Navbar>
      {!developers && <AppsWrapper />}
      {developers && <DevWrapper />}
      <div className="mt-4">
        <footer className="text-center text-muted mb-2">
          {' '}
          <small>
            Copyright © {new Date().getFullYear()} IIITD | All rights reserved |
            Created at{' '}
            <a
              href="https://midas.iiitd.edu.in/"
              rel="noopener noreferrer"
              target="_blank"
            >
              MIDAS LABS
            </a>{' '}
            by{' '}
            <a
              href="https://avisionx.net/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Avi Garg
            </a>
            .
          </small>{' '}
          <br />
          {developers && (
            <small
              onClick={(_e) => {
                setDevelopers(false);
              }}
              className="toggle-text"
            >
              Portals
            </small>
          )}
          {!developers && (
            <small
              onClick={(_e) => {
                setDevelopers(true);
              }}
              className="toggle-text"
            >
              Our Team
            </small>
          )}
        </footer>
      </div>
      <Modal
        centered
        className="modal"
        size="md"
        isOpen={modal}
        toggle={toggleModal}
        onClosed={() => {
          setFormErr('');
          window.history.pushState(
            '',
            document.title,
            window.location.pathname,
          );
        }}
      >
        <ModalBody>
          {/* <button
            className="btn"
            style={{
              position: 'absolute',
              top: '0px',
              right: '0px',
            }}
            onClick={toggleModal}
            type="button"
          >
            <Icon path={mdiClose} size={1} />
          </button> */}
          {renderModalForm(modalType)}
          {formErr && (
            <Alert className="mb-0 mt-3 small" color="danger">
              {formErr}
            </Alert>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

const CustomNavLink = styled(NavLink)`
  color: rgba(255, 255, 255, 0.8) !important;

  &:hover {
    color: white !important;
  }
`;

export default App;
