import React, { useState } from 'react';

const EditProfileForm = ({ handleEditProfile, prevState }) => {
  const [state, setState] = useState({
    ...prevState,
    username1: prevState.username,
  });

  const handleChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setState({ ...state, [name]: value });
  };

  return (
    <>
      {/* <form onSubmit={(e) => handleEditProfile(e, state)}>
        <h2 className="font-weight-bold text-primary mb-3">
          OSA Edit Profile Form
        </h2>
        <div className="form-group row">
          <p className="col-sm-3 col-form-label">First Name</p>
          <div className="col-sm-9 pl-3 pl-md-0 ">
            <input
              type="text"
              name="first_name"
              placeholder="First Name"
              className="form-control"
              value={state.first_name}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="form-group row">
          <p className="col-sm-3 col-form-label">Last Name</p>
          <div className="col-sm-9 pl-3 pl-md-0 ">
            <input
              type="text"
              name="last_name"
              placeholder="Last Name"
              className="form-control"
              value={state.last_name}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="form-group row">
          <p className="col-sm-3 col-form-label">Email</p>
          <div className="col-sm-9 pl-3 pl-md-0 ">
            <input
              type="email"
              required
              name="username"
              placeholder="Email"
              autoComplete="new-email"
              className="form-control"
              value={state.username}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="form-group row mb-1">
          <p className="col-sm-3 col-form-label">Re-Enter Email</p>
          <div className="col-sm-9 pl-3 pl-md-0 ">
            <input
              type="email"
              required
              name="username1"
              placeholder="Re-Enter Email"
              autoComplete="new-email"
              className="form-control"
              value={state.username1}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="form-group row">
          <p className="col-sm-3 col-form-label" />
          <div className="col-sm-9 pl-3 pl-md-0 small text-danger">
            Note: Changing Email Requires Email Confirmation.
          </div>
        </div>
        <div className="d-flex">
          <input
            className="btn btn-primary ml-auto"
            value="Update Profile"
            type="submit"
          />
        </div>
      </form> */}

      <form class="form" onSubmit={(e) => handleEditProfile(e, state)}>
        <p id="heading">OSA Profile</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100"
          height="80"
          fill="#3fada8"
          class="bi bi-person"
          viewBox="0 0 16 16"
          style={{ alignSelf: 'center', marginTop: '-30px' }}
        >
          {' '}
          <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />{' '}
        </svg>
        <div class="field">
          <input
            type="text"
            name="first_name"
            placeholder="First Name"
            className="input-field"
            value={state.first_name}
            onChange={handleChange}
            required
          />
        </div>
        <div class="field">
          <input
            type="text"
            name="last_name"
            placeholder="Last Name"
            className="input-field"
            value={state.last_name}
            onChange={handleChange}
            required
          />
        </div>
        <div class="field">
          <input
            type="email"
            required
            name="username1"
            placeholder="Re-Enter Email"
            autoComplete="new-email"
            className="input-field"
            value={state.username}
            onChange={handleChange}
          />
        </div>
        <div class="field">
          <input
            type="email"
            required
            name="username1"
            placeholder="Re-Enter Email"
            autoComplete="new-email"
            className="input-field"
            value={state.username1}
            onChange={handleChange}
          />
        </div>
        <div className="col-sm-20 pl-3 pl-md-0 small text-danger">
          Note: Changing Email Requires Email Confirmation.
        </div>
        <div class="btn">
          <button class="button1">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Update
            Profile&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </button>
        </div>
      </form>
    </>
  );
};

export default EditProfileForm;
